.profile-pic{
  height: 45vh;
  /* position: absolute;
  left: 0;
   */
  width: 22vw;
  border: 1px solid #007bff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
.circle{
  height: 200px;
  align-self: center;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: circle(50% at 50% 50%);
  background-color: skyblue; 
}
.head{
  width: 20px;
  height: 20px;
  background-color:black;
  border-radius: 50px;
  border: 5px solid black;
}
.human-body{
  width: 60%;
  height: 60px;
  transform: translateY(-12px);
  border: 5px solid black;
  /* border-radius: 30; */
  background-color: black;
  clip-path: circle(39.3% at 50% 100%);
}
.candidate_alignment{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.line{
  width: 95vw;
  height: 0.8px;
  translate: 0 -10px 0;
}

.candidate_section_name{
  display: flex;
  flex-direction: row;
  width: 95%;
  align-items: center;
  justify-content: space-between;
 
}
.create-candidate-addbtn{
  background-color: transparent;
  border: none;
  outline:none;
  color: #007bff;
}
.create-candidate-btnicon{
margin-top: auto;
}
.create-candidate-bottom-section{
  width: 100%;
  height: 10vh;
  background-color:#f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-candidate-bottom-section-save{
  background-color: #007bff;
  color: white;
  outline: none;
  margin-right: 1rem;
}
.create-candidate-bottom-section-cancel{
  background-color: transparent;
  color: black;
  border: 1px solid #007bff;
}
