   .dynamic-navbar {
    background: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .logo {
    margin-right: 20px;
  }
  
  .logo img {
    width: 120px;
    height: 30px;
  }
  
  .menu {
    flex-grow: 1;
    margin-left: 3%;
  }
  
  .menu ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px;
}
    .menu ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    transition: color 0.3s;
  }
  
  .menu ul li a:hover {
    color: #007BFF;
  }
  

  .menu .dropdown {
    position: relative;
    
  }
  
  .menu .dropdown:hover .submenu {
    display: flex;
    /* background-color: red; */
    flex-direction: column;
    margin-top: 2%;
  }
  
  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px;
    list-style-type: none;
    width: 350px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
 
  }
  
  .submenu li a {
    padding: 10px;
    color: #333;
    display: block;
    text-decoration: none;
  }
  
  .submenu li a:hover {
    background-color: #f1f1f1;
    color: #007BFF;
  }
  
  .actions {
    display: flex;
    gap: 10px;
   
  }
  
  .navbar-action-button {
    padding: 6px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    margin-right: 1%;
    width: fit-content;
    min-width:fit-content;
  
  }
  
  .action-button:hover {
    background-color: #0056b3;
   
   
  }
  
.dropdown-icon {
    margin-left: 8px;
    font-size: 12px;
    transition: transform 0.3s ease;
  }
  
  .menu .dropdown:hover .dropdown-icon {
    transform: rotate(180deg); 
  }
  .hamburger-button {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    
   
  }
 
 
  .dots-menu {
    position: relative; 
  }
  
  .dots-submenu {
    position: absolute;
    top: 100%; 
    left: 0; 
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    list-style: none;
    margin: 0;
    padding: 0 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  
  .dots-submenu li {
    padding: 0px 0px;
    background-color: transparent; 
  }
  
  .dots-submenu li:hover {
    background-color: #f4f4f4;

  }
  
  .dots-submenu a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 5px 10px;
  }
  .mobile-theme-switcher {
    display: none;
  }

  
  
  
  @media (max-width: 768px) {
   
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      background-color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 10px 20px;
      margin: 0;
  
  
    }
    .menu ul{
      display: flex;
      flex-direction: column;
    }
    .menu.mobile-menu-open {
      /* background-color: red; */
      display: flex;
      flex-direction: column;
    }
  
    .hamburger-button {
      display: block;
      margin-left:3% ;
    }
    .hamburger-button:hover{
      background-color:transparent;
      color: black;
    }
    .logo{
      margin-top: 6%;
    }
  
    .actions {
      display: none;
    }
    .mobile-actions{
      margin-top: 4%;
    }
    /* .mobile-action-button{
      padding: 6px 15px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      cursor: pointer;
      margin-top: 3%;
      
    } */
    .mobile-theme-switcher {
      display: block;
    }
    .desktop-theme-switcher {
      display: none;
    }
  }
  


































   