@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --background-color: #ffffff;
  --text-color: #000000;
  /* color-scheme: light !important; */
}

/* Dark Theme */
[data-theme="dark"] {
  --background-color: #000000;
  --text-color: #ffffff;
}

body, html {
  margin: 0;
  padding: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'inter',sans-serif;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  /* height: calc(100% - 110px); */
  /* overflow-y: auto; */

  /* background-color: red; */
  overflow-x: hidden;
 
}


