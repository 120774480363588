.setting-smtp-control-wrapper {
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
  }
  
  .setting-smtp-control-field {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .setting-smtp-control-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #333;
  }
  
  .setting-smtp-control-input {
    padding: 10px 12px;
    font-size: 14px;
    width: 220px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .setting-smtp-control-update-btn {
    margin-top: 16px;
    padding: 10px 16px;
    background-color: #4a85a8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .setting-smtp-control-update-btn:hover {
    background-color: #3a6d8a;
  }
  
  .setting-smtp-control-test-mail {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid #bbb;
  }
  
  .setting-smtp-control-send-btn {
    margin-top: 10px;
    margin-left: 2%;
    padding: 10px 14px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .setting-smtp-control-send-btn:hover {
    background-color: #218838;
  }
  