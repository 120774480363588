.candidate-interview-heading{
    font-weight: 600;
    color: black;
    padding-bottom: 0;
    margin-bottom: 0;
    
}
.candidate-interview-text{
    font-size: 13px;
    color: #7E89A5;
    line-height: 0.8;

}
.candidate-interview-label{
    color: #7E89A5;
    font-size: 14px;
    margin-right: 0.5rem;
}
.candidate-interview-select{
    padding: 5px 10px;
    border: 1px solid #7E89A5;
    border-radius: 2px;
    margin-right: 1rem;

}
.candidate-interview-select:focus{
    border: 0.5px solid #7E89A5;
}
.candidate-interview-line{
   height:10px;
   width: 1px;
   color: #7E89A5; 
}
.candidate-interview-toptext{
    font-size: 13px;
    color: black;
}
