.setting-dashboard-control-container {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
}

.setting-dashboard-control-header {
    display: flex;
    justify-content: space-between;
    background: #f8f9fa;
    padding: 0px 15px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.setting-dashboard-control-list {
    padding: 0;
}

.setting-dashboard-control-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px; 
    border-bottom: 1px solid #eee;
}

.setting-dashboard-control-item:last-child {
    border-bottom: none;
}

.setting-dashboard-control-label {
    color: #007bff;
    font-size: 14px;
}

.setting-dashboard-control-footer {
    display: flex;
    justify-content: flex-start; 
    padding: 10px 15px;
    margin-top: 10px;
}

.setting-dashboard-control-cancel-btn {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 6px 12px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 14px;
}

.setting-dashboard-control-save-btn {
    background: #007bff;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
}
/* ======================== BASE STYLES (Existing Code) ======================== */

.setting-dashboard-control-container {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
}

.setting-dashboard-control-header {
    display: flex;
    justify-content: space-between;
    background: #f8f9fa;
    padding: 10px 15px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.setting-dashboard-control-list {
    padding: 0;
}

.setting-dashboard-control-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px; /* Reduced vertical padding */
    border-bottom: 1px solid #eee;
}

.setting-dashboard-control-item:last-child {
    border-bottom: none;
}

.setting-dashboard-control-label {
    color: #007bff;
    font-size: 14px;
}

.setting-dashboard-control-footer {
    display: flex;
    justify-content: flex-start; /* Aligns buttons to the left */
    padding: 10px 15px;
    margin-top: 10px;
}

.setting-dashboard-control-cancel-btn {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 6px 12px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 14px;
}

.setting-dashboard-control-save-btn {
    background: #007bff;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
}

/* ========================== RESPONSIVE MEDIA QUERIES ========================== */

/* Mobile (575px and below) */
@media (max-width: 575px) {
    .setting-dashboard-control-container {
        width: 100%;
        padding: 10px;
    }

    .setting-dashboard-control-header {
        flex-direction: column;
        text-align: center;
        padding: 8px;
        font-size: 14px;
    }

    .setting-dashboard-control-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 6px 10px;
    }

    .setting-dashboard-control-label {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .setting-dashboard-control-footer {
        flex-direction: column;
        align-items: center;
    }

    .setting-dashboard-control-cancel-btn,
    .setting-dashboard-control-save-btn {
        width: 100%;
        font-size: 13px;
        text-align: center;
        padding: 8px;
    }
}
