/* Main Settings Container */
.settings-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  margin-top: 27vh;
}

/* Header */
.settings-header {
  padding: 15px;
  background: #ffffff;
  border-bottom: 1px solid #ddd;
}

/* Title */
.settings-title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

/* Layout Adjustments */
.settings-layout {
  display: flex;
}

/* Sidebar Styling */
.settings-sidebar {
  width: 15vw;
  min-width: 15vw;
  max-width: 15vw;
  background-color: var(--fullWhite);
  padding: 10px 15px;
  border-right: 1px solid #e0e0e0;
}

/* Hide Sidebar Scrollbar */
.settings-sidebar::-webkit-scrollbar {
  display: none;
}

.settings-sidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Sidebar Category */
/* .settings-category {
  margin-bottom: 12px;
} */

.category-title {
  font-size: 14px;
  font-weight: bold;
  color: #6b7280;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.settings-category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.settings-category li {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  /* margin-bottom: 4px; */
}

.settings-category li button {
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  font-family: poppins , 'san-serif';
  text-align: left;
  /* padding: 5px 0; */
}
.settings-category li button:hover{
  background-color: #EBF6FF;
}
.settings-category li button:focus {
  outline: none;
}

/* Right Content */
.settings-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; 
  max-height: 100%;
  background: #ffffff;
}

/* Right Content Scrollbar */
.settings-content::-webkit-scrollbar {
  width: 8px;
}

.settings-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.settings-content::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

/* Sticky Header */
.search-setting-header {
  position: fixed;
  margin-top: 17vh;
  z-index: 10;
  width: 100vw;
  top: 0;
  background: #ffffff;
  display: flex;
  gap: 2%;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.settings-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.search-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 320px; 
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  padding: 5px 10px;
}

.search-icon {
  font-size: 16px;
  color: #666;
  position: absolute;
  left: 10px;
}

.settings-search {
  width: 100%;
  padding: 8px 8px 8px 30px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #333;
}
/* Small devices (phones, 576px and below) */
@media screen and (max-width: 576px) {
  .settings-container {
    margin-top: 10vh;
  }

  .settings-layout {
    flex-direction: column;
  }

  .settings-sidebar {
    width: 100%;
    border-right: none;
    /* display: flex; */
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
  }

  .settings-content {
    padding: 15px;
  }

  .search-setting-header {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
    margin-top: 10vh;
  }

  .search-box {
    width: 100%;
  }

  .settings-title {
    font-size: 18px;
  }
}

/* Medium devices (tablets, 768px and below) */
@media screen and (max-width: 768px) {
  .settings-container {
    margin-top: 15vh;
  }

  .settings-layout {
    flex-direction: column;
  }

  .settings-sidebar {
    width: 100%;
    border-right: none;

    border-bottom: 1px solid #e0e0e0;
  }

  .settings-content {
    padding: 15px;
  }

  .search-setting-header {
    flex-direction: column;
    padding: 10px;
    width: 100%;
    margin-top: 12vh;
  }

  .search-box {
    width: 100%;
  }
}

/* Large devices (laptops, 992px and below) */
@media screen and (max-width: 992px) {
  .settings-container {
    margin-top: 20vh;
  }

  .settings-sidebar {
    width: 200px;
  }

  .settings-content {
    padding: 20px;
  }

  .search-setting-header {
    width: 100%;
    margin-top: 15vh;
  }

  .search-box {
    width: 280px;
  }
}

/* Extra large devices (desktops, 1200px and below) */
@media screen and (max-width: 1200px) {
  .settings-sidebar {
    width: 220px;
  }

  .settings-content {
    padding: 25px;
  }
}
