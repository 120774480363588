.commonTable-container {
    width: 96% ;
    max-width: 100%;
    /* height: 340px !important; */
    height: 60vh;
    margin: 1% auto;
    background:var(--fullWhite);
    border-radius: 4px;
    border: 1px solid var(--borderLightBlue);
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
 

  }
  
  .commontable-hyper-link:hover{
    text-decoration: underline;
  }
  .commonTable-search-container {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0.8rem;
    margin-top: 2%;
  }
  
  .commonTable-search-box {
    margin-left: 1%;
    display: flex;
    align-items: center;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color:var(--fullWhite);
    transition: border-color 0.3s ease;
  }
  
  .commonTable-search-box input {
    width: 250px;
    padding: 8px 18px;
    font-size: 14px;
    border: none;
    outline: none;
    background: transparent;
    color: #555;
  }
  
  
  .commonTable-search-icon {
    width: 50px;
    text-align: center;
    font-size: 18px;
    color: #777;
  }
  
  .commonTable-create-btn-header {
    background-color:var(--buttonBgLight);
    padding: 10px 28px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: black;
    padding: 8px 18px;
    border: 1px solid var(--borderLightBlue);
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    color: var(--primaryColor);
    margin-right: 0.8rem;
  }
  
  .commonTable-create-btn-header:hover {
    background-color:var(--primaryColor);
    color:var(--fullWhite);
  }
  
  .commonTable-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4vh;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    background-color: var(--fullWhite) !important;
  }
  
  .commonTable-title {
    display: flex;
    align-items: center;
    gap: 8px;

  }
  
  .commonTable-filter-btn {
    gap: 8px;
    padding: 10px 16px;
    color: var(--fullBlack);
    background: #f1f5f9;
    border: none;
    height: fit-content;
    border-radius: 8px;
    transition: all 0.2s ease;
    margin-right: 1rem;
  }
  
  .commonTable-filter-btn:hover {
    background: #e2e8f0;
    transform: translateY(-1px);
  }
  
  .commonTable-controls {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .commonTable-filter-column-btn,
  .commonTable-sort-by-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background:var(--fullWhite);
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .commonTable-filter-column-btn:hover,
  .commonTable-sort-by-btn:hover {
    border-color:var(--primaryColor);
    box-shadow: 0 2px 8px rgba(0, 123, 255, 0.15);
  }
  .commonTable-sort-by-btn:hover{
    background-color:var(--buttonBgLight) ;
  }
  .commonTable-wrapper {
    display: flex;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    overflow-x: hidden;
    box-sizing: border-box;
  
  }
  
  .commonTable-sidebar {
    width: 0;
    overflow: hidden;
    background: var(--fullWhite);
    border-right: 1px solid #ddd;
    transition: width 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .commonTable-sidebar.show {
    width: 250px;
    padding: 10px;
  }
  .commonTable-sidebar-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
  }
  .commonTable-close-btn {
    display: flex;
    justify-content: flex-end;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .commonTable-close-btn:hover {
    background: #f1f5f9;
    transform: rotate(90deg);
  }
  
  .commonTable-filter-options {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  /* .commonTable-scrollable-table {
    flex: 1;
    overflow-x: auto;
    max-width: 100%;
    overflow-y: auto;
 } */
   
 .commonTable-scrollable-table {
    flex: 1; 
    overflow-x: auto; 
    /* max-height: 280px;  */
    max-height: 58vh;
    overflow-y: auto;
    background-color:var(--fullWhite); 
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-left: 10px; 

  }

   
  
  .commonTable-scrollable-table::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  .commonTable-scrollable-table:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .commonTable-scrollable-table:hover::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background:var(--fullWhite);
    border-radius: 8px;
    overflow:visible;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  
  
  }
  
  th {
    padding: 14px 16px;
    text-align: left;
    border-bottom: 1px solid #f1f5f9;
    white-space: nowrap;
    background: #f8fafc;
    color: #64748b;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 0.5px;
  }
  
  td {
    padding: 2px 16px;
    text-align: left;
    border-bottom: 1px solid #f1f5f9;
    white-space: nowrap;
    font-size: 14px;
  }
  
  tr:hover {
    background: #f8fafc;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin-top: 20px;
    padding: 0px 25px;
    
  }
  
  .pagination-controls span {
    font-size: 14px;
    font-weight: bold;
  }
  
  .pagination-controls input {
    width: 50px;
    padding: 8px 12px;
    text-align: center;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
 
  
  .pagination-controls select {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .pagination-controls button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid var(--primaryColor);
    cursor: pointer;
    border-radius: 5px;
    background-color:var(--fullWhite);
    color:var(--fullBlack);
    transition: background 0.3s ease;
  }
  
  .pagination-controls button:disabled {
    cursor: not-allowed;
  }
  
  .pagination-controls button.active {
    background-color:#ebf6ff;
    font-weight: bold;
    color:var(--primaryColor);
  }
  
  .pagination-controls button:hover:not(:disabled) {
    background-color: #ddd;
    color:var(--fullBlack);
  }
  
/* CommonTable.css */
.commonTable-action-dropdown {
  position: absolute;
  background:var(--fullWhite);
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  z-index: 1;
  min-width: 160px;
  top: 100%;
  left: 0;
  
}

.commonTable-dropdown-item {
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
}

.commonTable-dropdown-item:hover {
  background-color: #f5f5f5;
}


  
  .commonTable-filter-menu-popup {
    position: fixed;
    top: 15%;
    right: 20px;
    width: 35vw;
    background:var(--fullWhite);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0 0 15px 0;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 10;
    opacity: 0;
    transform: translateX(100%);
    pointer-events: none;
  }
  
  .commonTable-filter-menu-popup.open {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
  }
  
  .commonTable-filter-menu-popup h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .commonTable-filter-menu-popup .commonTable-filter-close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .commonTable-filter-menu-header {
    display: flex;
    padding: 0 15px 0 15px;
    justify-content: space-between;
    background-color: #2c3e50;
    color:var(--fullWhite);
  }
  
  .commonTable-filter-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
  }
  
  .commonTable-filter-options label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  
  .commonTable-filter-close-btn {
    color:var(--fullWhite);
    background-color: transparent;
    outline: none;
    border: none;
  }
  
  .commonTable-filter-options input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
  
  .commonTable-filter-actions {
    margin: 5% 0 0 5%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .commonTable-cancel-btn {
    background: none;
    border: 1px solid #ccc;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    color: rgb(10, 0, 0);
  }
  
  .commonTable-save-btn {
    background: #007bff;
    color:var(--fullWhite);
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .commonTable-save-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .commonTable-sortby-menu {
    position:absolute;
    top: 38%;
    right: 0;
    background:var(--fullWhite);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    min-width: 160px;
    z-index: 10;
  }
  
  .commonTable-sortby-menu h4 {
    margin: 0;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  
  .commonTable-sortby-menu button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
  
  .commonTable-sortby-menu button:hover {
    background: #f4f4f4;
  }
  
  @media (max-width: 768px) {
    .commonTable-header {
      flex-direction: column;
      height: auto;
      padding: 10px;
      gap: 8px;
    }
  
    .commonTable-controls {
      width: 100%;
      flex-wrap: wrap;
    }
  
    .commonTable-controls input {
      width: 100%;
      margin: 0;
    }
  
    th,
    td {
      padding: 10px;
      font-size: 13px;
    }
  }
  
  @media (max-width: 480px) {
    .commonTable-container {
      width: 100% !important;
      margin: 10px 0;
    }
  
    .commonTable-filter-btn,
    .commonTable-filter-column-btn,
    .commonTable-sort-by-btn {
      padding: 8px 12px;
      font-size: 13px;
    }
  
    .commonTable-pagination-controls {
      gap: 10px;
    }
  
    .commonTable-pagination-controls button {
      padding: 6px 15px;
    }
  }