@keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


.canidate-adddocument-popup{
    position: fixed;
    top:9vh;
    right: 0;
    width: 50vw;
    height: 86vh;
    background:var(--fullWhite);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0 0 20px 0;
    border-radius: 8px;
    z-index: 10;
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 0.6s ease-in-out;
    pointer-events: none;
    
   
    

}
.canidate-adddocument-popup.open{
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}
.canidate-adddocument-popup-header h3{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.canidate-adddocument-popup-header {
    display: flex;
    padding: 0 15px 0 15px;
    justify-content: space-between;
    background-color:white;
    color:var(--fullBlack);
    border-radius: 8px 0px 0px 0px;
    border-bottom: 1px solid #CED4DA;

  }
  .canidate-adddocument-popup .canidate-adddocument-popup-close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color:var(--fullBlack);
}
.candidate-adddocument-popup-content{
    flex: 1; 
    overflow-y: auto; 
    padding: 15px;
    max-height: calc(100vh - 50px); 
    display: flex;
    flex-wrap: wrap;

   
}
.candidate-adddocument-popup-inputcontainer{
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-right: 3rem;
    padding-bottom: 1rem;
}
.candidate-adddocument-popup-label{
    color:#7E89A5 ;
    font-weight: 400;
    padding-bottom: 0.2rem;
    font-size: 14px;
}
.candidate-adddocument-popup-input{
    padding: 8px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;

}
.candidate-adddocument-popup-input:focus{
    border: 1px solid #ccc;
}
.ci-popup-line{
    height: 1px;
    width: 100%;
    background-color:#badfff;
    margin: 17.5rem 0 10px 0;
   
    
  }
  .candidate-adddocument-popup-bottomCon{
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}
  .adddocument-popup-cancelbtn{
    padding: 8px 15px;
    border-radius: 5px;
    background-color: transparent;
    color: black;
    border: 1px solid #007bff;
    margin-right: 0.5rem;
  }
  .adddocument-popup-savebtn{
    padding: 8px 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
  }
.candidate-adddocument-popup-fixedcontainer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.uploaded-file-name{
  background-color: #F5F7FA;
  font-size: 12px;
  padding: 0px 8px;
  border-radius: 5px;
}