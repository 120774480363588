.winspire-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1f3447;
  padding: 10px 20px;
  height: 6vh; /* Ensure it has a fixed height */
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 12; 
}
  .winspire-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .user-info {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

  }
  
  .profile-icon {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .profile-icon-react {
    color: white;
  }
  
  .welcome-text {
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .welcome-text p{
    font-size: 20px;
    /* margin-top: 2%; */
  }
  .winspire-username{
    margin-top: 6%;
  }
  .dropdown-arrow {
    font-size: 20px;
    /* margin-left: 5px; */
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* background-color: red; */
  }

  .user-info.open .dropdown-arrow {
    transform: rotate(180deg);
  }
  


.user-menu {
  position: absolute;
  top: 50px;
  left: 89%;
  background: white;
  color: black;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 120px;
 
}

  
  .logout-btn {
    background: #d9534f;
    color: white;
    border: none;
    padding: 8px 12px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    
  }
  
  .logout-btn:hover {
    background: #c9302c;
  }
  
  .winspire-header-center {
    flex-grow: 1;
    margin-right: 8%;
    display: flex;
    justify-content: center;
  }
  .winspire-logo {
    margin-right: 20%;
    height: 35px;
    width: auto;
    object-fit: contain;
    border-color: red;
  }
  .winspire-header-right {
    display: flex;
    align-items: center;
    margin-right: 3%;
    gap: 15px;
  }
  
  .header-icon {
    font-size: 18px;
    cursor: pointer;
    color: white;
  }
  
  .header-icon:hover {
    color: #ddd;
  }
.winspire-header-center{
  text-decoration: none;
}
.header-icon-link{
  color: white;
}


  /* Responsive for mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .winspire-header {
    flex-direction: column;
    height: auto;
   padding: 0;
    text-align: center;
  
  }

  .winspire-header-left {
    flex-direction: column;
 
  }

  .winspire-header-center {
    order: -1;

  }

  .winspire-logo {
    height: 30px;
    margin: 0;
  }

  .winspire-header-right {
    flex-direction: row;
    justify-content: center;
  
  
  }

  .user-menu {
    left: 50%;
    transform: translateX(-50%);
    top: 60px;
    width: 80%;

  }

 
  
}

/* Responsive for large screens (1920px and above) */
@media (min-width: 1920px) {
  .winspire-header {
    max-width: 100%;
    margin: 0 auto;
    height: 7vh;

  }

  .winspire-logo {
    height: 50px;
  }

  .header-icon {
    font-size: 22px;
  }

  .welcome-text p {
    font-size: 24px;
  }

  .user-menu {
    top: 60px;
    left: 92%;
    min-width: 150px;
    padding: 15px;
  }

  .logout-btn {
    font-size: 16px;
    padding: 10px 16px;
  }
}

/* Extra large screens (3000px and above) */
@media (min-width: 3000px) {
  .winspire-header {
    max-width: 100%;
    height: 7vh;
  }

  .winspire-logo {
    height: 60px;
  }

  .header-icon {
    font-size: 26px;
  }

  .welcome-text p {
    font-size: 28px;
  }

  .user-menu {
    top: 80px;
    left: 94%;
    min-width: 180px;
    padding: 20px;
  }

  .logout-btn {
    font-size: 18px;
    padding: 12px 20px;
  }
}