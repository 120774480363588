.candidata-info-detail-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap:20px;
    padding: 1rem 1.5rem;
}
.candidata-info-detail-subcontainer{
    display: flex;
    flex-direction: column;
}
.candidata-info-detail-key{
    font-size: 12px;
    color:#7E89A5;
}
.candidata-info-detail-value{
    font-size: 14px;
    font-weight: 400;

}
.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip-container .tooltip-text {
    /* visibility: hidden; */
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 150%; 
    left: 50%;
    transform: translateX(-45%);
    white-space: wrap;
    z-index: 10;
    opacity: 0;
    width:fit-content;
    transition: opacity 0.2s ease-in-out;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  
@media (max-width:575.98px) {
 .candidata-info-detail-container{
    grid-template-columns: repeat(2,1fr); 
 }   
    
}