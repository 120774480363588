.change-prefix-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background: #fff;
  }
  
  .change-prefix-form-group {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .change-prefix-field {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .change-prefix-field label {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 5px;
  }
  
  #change-prefix-dropdown,
  .change-prefix-selected-entity,
  .change-prefix-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  
  .change-prefix-selected-entity {
    background-color: #f8f9fa;
    color: #6c757d;
  }
  
  .change-prefix-button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  .change-prefix-cancel-button {
    background: white;
    border: 1px solid #ccc;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .change-prefix-save-button {
    background: #007bff;
    color: white;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .change-prefix-save-button:hover {
    background: #0056b3;
  }
  /* Tablets (768px and below) */
  @media (max-width: 768px) {
    .change-prefix-container {
      max-width: 100%;
      padding: 15px;
    }
  
    .change-prefix-form-group {
      flex-direction: column; /* Stack inputs vertically */
      gap: 15px;
    }
  
    .change-prefix-button-group {
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px;
    }
  }
  
  /* Mobile (575px and below) */
  @media (max-width: 575px) {
    .change-prefix-container {
      padding: 10px;
    }
  
    .change-prefix-field label {
      font-size: 12px;
    }
  
    #change-prefix-dropdown,
    .change-prefix-selected-entity,
    .change-prefix-input {
      font-size: 12px;
      padding: 8px;
    }
  
    .change-prefix-cancel-button,
    .change-prefix-save-button {
      padding: 10px;
      font-size: 14px;
      width: 100%; 
    }
  
    .change-prefix-button-group {
      flex-direction: column;
      gap: 8px;
    }
  }